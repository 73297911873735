<template>
	<div class="FM_page-account">
		<router-view/>
	</div>
</template>

<script>
	export default {
		name: "Index"
	}
</script>

<style scoped>

</style>